import React, { useState, useRef } from "react";
import waiverTextFile from "../assets/waiver.txt";
import {
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithPhoneNumber,
  signInWithCredential,
} from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  CssBaseline,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { db, auth } from "../firebase";
import {
  query,
  collection,
  getDocs,
  addDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import Copyright from "../components/Copyright";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const RegisterPicnic = () => {
  const sendCodeButtonRef = useRef(null);
  const [errorList, setErrorList] = useState([]);
  const [electronicSignatureError, setElectronicSignatureError] =
    useState(false);
  const [optInError, setOptInError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [text, setText] = React.useState(null);
  const Status = {
    BeforeSend: "BeforeSend",
    AfterSend: "AfterSend",
    Success: "Success",
    Failure: "Failure",
    BadCodeAuth: "BadCodeAuth",
    Previously: "Previously",
  };
  const [registeredStatus, setRegisteredStatus] = useState(Status.BeforeSend);
  const [badTriesCount, setBadTriesCount] = useState(0);

  const optInMessage = "I have read the above waiver.";
  const electronicSignatureMessage =
    "Please type in your name as an electronic signature indicating that you are 18 years of age or older and you agree with the terms described herein.";

  const optInLabelRed = (
    <Typography variant="h6" style={{ color: "#FA2200" }}>
      {optInMessage}
    </Typography>
  );
  const optInLabelBlack = <Typography variant="h7">{optInMessage}</Typography>;

  const electronicSignatureRed = (
    <Typography variant="h6" style={{ color: "#FA2200" }}>
      {electronicSignatureMessage}
    </Typography>
  );
  const electronicSignatureBlack = (
    <Typography variant="h7">{electronicSignatureMessage}</Typography>
  );

  const initialPicnicAssignment = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    children: "",
    userId: "",
    optIn: false,
    electronicSignature: "",
  };
  const [newPicnicAssignment, setPicnicAssignment] = useState(
    initialPicnicAssignment
  );

  const waiver = () => {
    fetch(waiverTextFile)
      .then((response) => response.text())
      .then((textContent) => {
        setText(textContent);
      });
    return text || "Loading...";
  };

  const onInputChange = (e, type) => {
    const value = e.target.value;
    if (type === "firstName") setFirstNameError(false);
    if (type === "lastName") setLastNameError(false);
    if (type === "phoneNumber") setPhoneError(false);
    if (type === "emailAddress") setEmailError(false);
    if (type === "electronicSignature") setElectronicSignatureError(false);
    if (value !== undefined) {
      setPicnicAssignment({ ...newPicnicAssignment, [type]: value });
    }
  };

  const onVerificationCodeChange = (e) => {
    setVerificationCodeError(false);
    setVerificationCode(e.target.value);
  };

  const onOptInChange = (value) => {
    setOptInError(false);
    if (value !== undefined) {
      setPicnicAssignment({ ...newPicnicAssignment, optIn: value });
    }
  };

  const sendCodeToPhone = async (e) => {
    console.log("newPicnicAssignment", newPicnicAssignment);
    if (newPicnicAssignment.children !== "") {
      newPicnicAssignment.children = newPicnicAssignment.children.replace(
        /\n/g,
        ", "
      );
    }
    if (isPicnicFormError()) {
      return;
    }
    auth.settings.appVerificationDisabledForTesting = false;

    const recaptchaVerifier = new RecaptchaVerifier(
      sendCodeButtonRef.current,
      {
        size: "invisible",
      },
      auth
    );

    signInWithPhoneNumber(
      auth,
      newPicnicAssignment.phoneNumber,
      recaptchaVerifier
    )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setVerificationId(confirmationResult.verificationId);
        setRegisteredStatus(Status.AfterSend);
      })
      .catch((error) => {
        console.error("Error; SMS not sent", error);
        // Error; SMS not sent
        // ...
      });

    function isPicnicFormError() {
      let currentError = false;
      let errorArray = [];

      if (
        newPicnicAssignment.firstName === "" ||
        newPicnicAssignment.firstName === undefined
      ) {
        setFirstNameError(true);
        errorArray.push("Please enter first name");
        currentError = true;
      }

      if (
        newPicnicAssignment.lastName === "" ||
        newPicnicAssignment.lastName === undefined
      ) {
        setLastNameError(true);
        errorArray.push("Please enter last name");
        currentError = true;
      }

      if (newPicnicAssignment.emailAddress.trim() === "") {
        setEmailError(true);
        errorArray.push("Please input email address");
        currentError = true;
      }

      if (
        newPicnicAssignment.phoneNumber === "" ||
        newPicnicAssignment.phoneNumber === undefined ||
        newPicnicAssignment.phoneNumber.length < 12
      ) {
        setPhoneError(true);
        errorArray.push("Please enter phone number");
        currentError = true;
      }
      if (newPicnicAssignment.optIn === false) {
        setOptInError(true);
        errorArray.push("Please agree to the waiver");
        currentError = true;
      }
      if (
        newPicnicAssignment.electronicSignature === undefined ||
        newPicnicAssignment.electronicSignature.trim() === ""
      ) {
        setElectronicSignatureError(true);
        errorArray.push("Please input electronic signature");
        currentError = true;
      }
      setErrorList(errorArray);
      return currentError;
    }
  };

  const handleVerifyCode = async () => {
    setErrorList([]);

    const isVerificationCodeError = () => {
      let currentError = false;
      let errorArray = [];

      if (
        verificationCode === "" ||
        verificationCode === undefined ||
        verificationCode.length < 6
      ) {
        setVerificationCodeError(true);
        currentError = true;
      }

      setErrorList(errorArray);
      return currentError;
    };

    if (isVerificationCodeError()) {
      return;
    }

    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );

    await signInWithCredential(auth, credential)
      .then((userCredential) => {
        console.log("userCredential", userCredential);
        createPicnicRegistration(userCredential.user.uid);
      })
      .catch((error) => {
        setRegisteredStatus(Status.BadCodeAuth);

        if (badTriesCount > 1) {
          setRegisteredStatus(Status.Failure);
        }
        setBadTriesCount(badTriesCount + 1);
        setVerificationCode("");
        console.error("error", error);
      });
  };
  const AddDocumentToDatabase = async (uid) => {
    try {
      await addDoc(collection(db, "user-picnic-2024"), {
        firstName: newPicnicAssignment.firstName,
        lastName: newPicnicAssignment.lastName,
        phoneNumber: newPicnicAssignment.phoneNumber,
        emailAddress: newPicnicAssignment.emailAddress,
        children: newPicnicAssignment.children,
        optIn: newPicnicAssignment.optIn,
        electronicSignature: newPicnicAssignment.electronicSignature,
        updatedDtm: Timestamp.now(),
        createdDtm: Timestamp.now(),
        userId: uid,
      }).then(() => {
        setRegisteredStatus(Status.Success);
      });
    } catch (e) {
      setRegisteredStatus(Status.Failure);
      setErrorList(...errorList, e.message);
      console.log(e.message);
    }
  };

  const CheckAndSave = (userId) => {
    const q = query(
      collection(db, "user-picnic-2024"),
      where("userId", "==", userId)
    );

    getDocs(q).then((snapshot) => {
      const docs = snapshot.docs;

      if (docs.length > 0) {
        setRegisteredStatus(Status.Previously);
      } else {
        AddDocumentToDatabase(userId);
      }
    });
  };

  const createPicnicRegistration = (uid) => {
    CheckAndSave(uid);
  };

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            borderRadius: 4,
            padding: 3,
          }}
        >
          <Typography component="h1" variant="h5">
            Registration for Lake of Dreams Picnic
          </Typography>
          <Typography variate="h5" align="right">
            June 15, 2024
          </Typography>

          <Box
            component="form"
            sx={{
              mt: 3,
            }}
          >
            {registeredStatus !== Status.BeforeSend ? null : (
              <>
                <Grid container spacing={2}>
                  {/* first name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={firstNameError}
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      onChange={(e, name) => {
                        onInputChange(e, "firstName");
                      }}
                      autoFocus
                    />
                  </Grid>

                  {/* last name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={lastNameError}
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      onChange={(e, name) => {
                        onInputChange(e, "lastName");
                      }}
                    />
                  </Grid>

                  {/* phone */}
                  <Grid item xs={12} sm={6}>
                    <PhoneInput
                      error={phoneError}
                      required
                      fullWidth
                      disableCountryCode={false}
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      id="phone"
                      label="Phone Number"
                      name="phone"
                      autoComplete="phone"
                      country={"us"}
                      value={phoneNumber}
                      onChange={(value, country, e, name) => {
                        setPhoneNumber(value);
                        onInputChange(e, "phoneNumber");
                      }}
                    />
                  </Grid>

                  {/* email */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={emailError}
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      onChange={(e, name) => {
                        onInputChange(e, "emailAddress");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      maxRows={8}
                      fullWidth
                      id="children"
                      label="Children's Full Names (Under 18 only), one child per line"
                      name="children"
                      autoComplete="children"
                      onChange={(e, name) => {
                        onInputChange(e, "children");
                      }}
                    />
                  </Grid>
                </Grid>

                <TextField
                  sx={{ mt: 3, mb: 2 }}
                  id="outlined-multiline-static"
                  type="text"
                  label="Waiver"
                  variant="outlined"
                  multiline={true}
                  fullWidth
                  maxRows={4}
                  onChange={(e) => setText(e.target.value)}
                  value={waiver()}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <FormGroup sx={{ mb: 2 }}>
                  <FormControlLabel
                    required={true}
                    name="optIn"
                    control={<Checkbox />}
                    onChange={(e, name) => {
                      onOptInChange(e.target.checked);
                    }}
                    label={optInError ? optInLabelRed : optInLabelBlack}
                  />
                </FormGroup>
                {electronicSignatureError
                  ? electronicSignatureRed
                  : electronicSignatureBlack}
                <TextField
                  error={electronicSignatureError}
                  required
                  fullWidth
                  name="electronicSignature"
                  label="Electronic Signature"
                  type="electronicSignature"
                  id="electronicSignature"
                  // autoComplete="emergency"
                  onChange={(e, name) => {
                    onInputChange(e, "electronicSignature");
                  }}
                />
              </>
            )}
            <Box
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <Typography
                sx={{ mt: 3 }}
                variant="h6"
                style={{ color: "#FA2200" }}
              >
                {errorList.length > 0 ? "Errors:" : ""}
              </Typography>
              <List>
                {errorList.map((errorText, i) => {
                  return (
                    <ListItem key={i} disablePadding sx={{ ml: 3 }}>
                      <ListItemText
                        primary={errorText}
                        style={{ color: "#FA2200" }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Box>

            <Grid container spacing={2}>
              {registeredStatus === Status.Success ||
              registeredStatus === Status.Failure ||
              registeredStatus === Status.AfterSend ||
              registeredStatus === Status.Previously ? null : (
                <>
                  <Grid item xs={12} sm={6} sx={{ paddingBottom: 7 }}>
                    <Typography variate="h5" align="left" fontWeight="bold">
                      * Note: To complete registration, we need to send a code
                      to your phone {newPicnicAssignment.phoneNumber}. Please
                      click the button and then check your phone.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      //   type="submit"
                      ref={sendCodeButtonRef}
                      id="send-code-button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={sendCodeToPhone}
                    >
                      {registeredStatus === Status.BadCodeAuth
                        ? " Re-Send Code to Phone"
                        : "Send Code to Phone"}
                    </Button>
                  </Grid>
                </>
              )}

              {registeredStatus === Status.Success ||
              registeredStatus === Status.Failure ||
              registeredStatus === Status.BeforeSend ||
              registeredStatus === Status.Previously ? null : (
                <>
                  <Typography
                    variant="h6"
                    style={{ color: "#FA2200" }}
                  ></Typography>
                  <Grid item xs={12}>
                    {registeredStatus === Status.BadCodeAuth ? (
                      <Typography variant="h7" style={{ color: "#FA2200" }}>
                        {" "}
                        The code you entered was incorrect. Please try again or
                        click 'Re-Send' above.
                      </Typography>
                    ) : (
                      <Typography variant="h7">
                        Sent! Please check your phone for a text message from
                        us."
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variate="h5" align="left" fontWeight="bold">
                      Enter CODE from your phone here:
                    </Typography>

                    <TextField
                      //   required
                      error={verificationCodeError}
                      value={verificationCode}
                      fullWidth
                      id="otpCode"
                      label="Code"
                      name="otpCode"
                      onChange={onVerificationCodeChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      //   type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleVerifyCode}
                    >
                      Submit code to complete registration
                    </Button>{" "}
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <Typography
                        sx={{ mt: 3 }}
                        variant="h7"
                        style={{ color: "#FA2200" }}
                      >
                        {verificationCodeError
                          ? "Please enter the code that was sent to your phone.  It should be 6 digits."
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            {registeredStatus === Status.Success ? (
              <Box
                sx={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: 3,
                  borderRadius: 4,
                  padding: 3,
                }}
              >
                <Typography variant="h5">
                  {`Registration for ${newPicnicAssignment.firstName} ${newPicnicAssignment.lastName} is complete. Thank you!`}
                </Typography>
              </Box>
            ) : null}
            {registeredStatus === Status.Previously ? (
              <Box
                sx={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: 3,
                  borderRadius: 4,
                  padding: 3,
                }}
              >
                <Typography variant="h5">
                  {`You've previously registered.  No further action required.  Thank you!`}
                </Typography>
              </Box>
            ) : null}
            {registeredStatus === Status.Failure ? (
              <Box
                sx={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: 3,
                  borderRadius: 4,
                  padding: 3,
                }}
              >
                <Typography variant="h5" style={{ color: "red" }}>
                  {badTriesCount > 0
                    ? "Number of tries exceeded"
                    : `There was a system error. `}{" "}
                  <Button
                    onClick={() => {
                      setRegisteredStatus(Status.BeforeSend);
                      setBadTriesCount(0);
                      setErrorList([]);
                    }}
                  >
                    Please try again later.
                  </Button>
                  {` If the problem persists, please contact us.`}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </div>
  );
};

export default RegisterPicnic;
