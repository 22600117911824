import { Route, Routes } from "react-router-dom";
import Admin from "./pages/Admin";
import RegisterPicnic from "./pages/RegisterPicnic";

const AppRoutes = function () {
  // const defaultRouteForUser = () => {
  //   if (false) {
  //     return <Admin />;
  //   } else {
  //     return <RegisterPicnic />;
  //   }
  // };

  return (
    <Routes>
      <Route path="/" element={<RegisterPicnic />} />
      <Route path="/register-picnic" element={<RegisterPicnic />} />
      <Route path="/admin" element={<Admin />} />
    </Routes>
  );
};
export default AppRoutes;
