import React from "react";
import { Fab } from "@mui/material";
import LodAppBar from "./components/LodAppBar";
import AppRoutes from "./AppRoutes";
import { RecoilRoot } from "recoil";

const EnvFab = () => {
  return (
    <Fab
      sx={{
        position: "absolute",
        top: 60,
        right: "1%",
        color: "yellow",
        backgroundColor: "#dd2c00",
        borderColor: "black",
      }}
      variant="extended"
      href={process.env.REACT_APP__PR_URL}
    >
      {`ENV: ${process.env.REACT_APP__ENV}`}
    </Fab>
  );
};

function App() {
  return (
    <React.StrictMode>
      {process.env.REACT_APP__ENV !== "PROD" ? <EnvFab /> : ""}
      <RecoilRoot>
        <LodAppBar />
        <AppRoutes />
      </RecoilRoot>
    </React.StrictMode>
  );
}

export default App;
