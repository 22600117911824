import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import WaterIcon from "@mui/icons-material/Water";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { currentUserIsAdminAtom, currentUserIsAuthAtom } from "./Atom";

function LodAppBar() {
  const navigate = useNavigate();
  const [, setCurrentUserIsAuth] = useRecoilState(currentUserIsAuthAtom);
  const [currentUserIsAdmin, setCurrentUserIsAdmin] = useRecoilState(
    currentUserIsAdminAtom
  );

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        console.log("User is signed in");
        setCurrentUserIsAuth(true);
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            setCurrentUserIsAdmin(true);
            return;
          }
        });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [setCurrentUserIsAuth, setCurrentUserIsAdmin]);

  const handleClick = () => {
    if (currentUserIsAdmin) return navigate("/admin");
    return navigate("/register-picnic");
  };
  // const navigate = useNavigate();
  // const menuItems = [];
  // console.log("Rendering AppBar");

  // Avatar menu
  // let avatarMenu = ["Logout"];
  // if (process.env.REACT_APP__ENV === "LocalEnv" && currentUser.admin)
  //   avatarMenu.push("RefreshData");

  // let menuItems = [];

  // if (currentUser.admin) {
  //   menuItems = [
  //     { label: "AdminPage", path: "/admin" },
  //     { label: "Chairperson", path: "/chair" },
  //     { label: "Register", path: "/register-retreat" },
  //     { label: "My Retreat", path: "/user-home" },
  //   ];
  // } else if (currentUser.chair || currentUser.coordinator) {
  //   menuItems = [
  //     { label: "Chairperson", path: "/chair" },
  //     { label: "Register", path: "/register-retreat" },
  //     { label: "My Retreat", path: "/user-home" },
  //   ];
  // } else if (
  //   !currentUser.chair &&
  //   !currentUser.coordinator &&
  //   !currentUser.admin
  // ) {
  //   menuItems = [
  //     { label: "Register", path: "/register-retreat" },
  //     { label: "My Retreat", path: "/user-home" },
  //   ];
  // }

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };

  // const handleCloseNavMenu = (e) => {
  //   setAnchorElNav(null);
  //   if (e.target.firstChild !== null) {
  //     navigate(e.target.dataset.path);
  //   }
  // };

  // const handleCloseUserMenu = async (e) => {
  //   if (e.target.firstChild !== null) {
  //     if (e.target.firstChild.nodeValue === "Profile") {
  //       setAnchorElUser(null);
  //       throw new Error("User Profile is not implemented");
  //     }
  //     if (e.target.firstChild.nodeValue === "Logout") {
  //       setAnchorElUser(null);
  //       await logout();
  //       setCurrentUser({});
  //       navigate("/");
  //     }
  //     if (e.target.firstChild.nodeValue === "RefreshData") {
  //       setAnchorElUser(null);
  //       navigate("/seed-data");
  //     }
  //   }
  //   setAnchorElUser(null);
  // };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <WaterIcon sx={{ display: { xs: "none", md: "flex" }, mr: 2 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 4,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LODI Picnic 2024
          </Typography>
          <WaterIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Lake Of Dreams
          </Typography>
          {currentUserIsAdmin ? (
            <Button
              onClick={handleClick}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Admin
            </Button>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default LodAppBar;
