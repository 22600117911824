import React, { useRef, useEffect } from "react";
import ExcelJS from "exceljs";
import {
  Grid,
  Box,
  Container,
  Button,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  currentUserIsAdminAtom,
  currentUserIsAuthAtom,
} from "../components/Atom";
import {
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithPhoneNumber,
  signInWithCredential,
} from "firebase/auth";
import { auth } from "../firebase";
import PhoneInput from "react-phone-input-2";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import { saveAs } from "file-saver";

const Admin = () => {
  const currentUserIsAuth = useRecoilValue(currentUserIsAuthAtom);
  const currentUserIsAdmin = useRecoilValue(currentUserIsAdminAtom);
  const sendCodeButtonRef = useRef(null);
  const [verificationId, setVerificationId] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const Status = {
    BeforeSend: "BeforeSend",
    AfterSend: "AfterSend",
    Success: "Success",
    Failure: "Failure",
    BadCodeAuth: "BadCodeAuth",
    Previously: "Previously",
  };
  const [registeredStatus, setRegisteredStatus] = React.useState(
    Status.BeforeSend
  );
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [badTriesCount, setBadTriesCount] = React.useState(0);
  const [phoneError, setPhoneError] = React.useState(false);
  const [errorList, setErrorList] = React.useState([]);
  const [verificationCodeError, setVerificationCodeError] =
    React.useState(false);
  const [attendees, setAttendees] = React.useState([]);
  const [msg, setMsg] = React.useState("");

  useEffect(() => {
    const fetchAllDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "user-picnic-2024"));
      const documents = querySnapshot.docs.map((doc) => doc.data());
      setAttendees(documents);
      const childrenCount = documents.reduce((acc, curr) => {
        if (curr.children.trim() === "") {
          return acc;
        } else {
          return acc + curr.children.split(",").length;
        }
      }, 0);

      const msgToDisplay = `Adults Registered: ${documents.length} (with ${childrenCount} children)`;
      setMsg(msgToDisplay);
    };

    fetchAllDocuments().catch(console.error);
  }, []);

  const onVerificationCodeChange = (e) => {
    setVerificationCodeError(false);
    setVerificationCode(e.target.value);
  };

  const downloadAttendees = async () => {
    console.log("attendees", attendees);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Picnic Attendees");

    worksheet.columns = [
      { header: "LastName", key: "last", width: 15 },
      { header: "FirstName", key: "first", width: 15 },
      { header: "Phone", key: "phone", width: 20 },
      { header: "Email", key: "email", width: 25 },
      { header: "Children", key: "children", width: 40 },
      { header: "OptIn", key: "optin", width: 10 },
      { header: "ElectronicSignature", key: "signature", width: 30 },
      { header: "Created Date", key: "createdDtm", width: 20 },
    ];

    attendees
      .sort((a, b) => a.lastName.localeCompare(b.lastName))
      .forEach((attendee) => {
        const createdDate = attendee.createdDtm.toDate();
        const createdDateString = createdDate.toLocaleDateString("en-US");

        worksheet.addRow({
          last: attendee.lastName,
          first: attendee.firstName,
          phone: attendee.phoneNumber,
          email: attendee.emailAddress,
          children: attendee.children,
          optin: attendee.optIn,
          signature: attendee.electronicSignature,
          createdDtm: createdDateString,
        });
        //new Date(1970, 1, 1),
      });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Picnic_Attendees.xlsx");
  };

  const onPhoneChange = (e) => {
    setPhoneError(false);
    setErrorList([]);
    setPhoneNumber(e.target.value);
  };

  const sendCodeToPhone = async (e) => {
    if (isPicnicFormError()) {
      return;
    }

    auth.settings.appVerificationDisabledForTesting = false;

    const recaptchaVerifier = new RecaptchaVerifier(
      sendCodeButtonRef.current,
      {
        size: "invisible",
      },
      auth
    );

    signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setVerificationId(confirmationResult.verificationId);
        setRegisteredStatus(Status.AfterSend);
      })
      .catch((error) => {
        console.error("Error; SMS not sent", error);
        // Error; SMS not sent
        // ...
      });

    function isPicnicFormError() {
      let currentError = false;
      let errorArray = [];

      console.log("phoneNumber", phoneNumber);
      console.log("phoneNumber.length", phoneNumber.length);
      if (
        phoneNumber === "" ||
        phoneNumber === undefined ||
        phoneNumber.length < 17
      ) {
        setPhoneError(true);
        errorArray.push("Please enter phone number");
        currentError = true;
      }

      setErrorList(errorArray);
      return currentError;
    }
  };

  const handleVerifyCode = async () => {
    setErrorList([]);

    const isVerificationCodeError = () => {
      let currentError = false;
      let errorArray = [];

      if (
        verificationCode === "" ||
        verificationCode === undefined ||
        verificationCode.length < 6
      ) {
        setVerificationCodeError(true);
        currentError = true;
      }

      setErrorList(errorArray);
      return currentError;
    };

    if (isVerificationCodeError()) {
      return;
    }

    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );

    await signInWithCredential(auth, credential).catch((error) => {
      setRegisteredStatus(Status.BadCodeAuth);

      if (badTriesCount > 1) {
        setRegisteredStatus(Status.Failure);
      }
      setBadTriesCount(badTriesCount + 1);
      setVerificationCode("");
      console.error("error", error);
    });
  };

  return (
    <div>
      {currentUserIsAdmin ? (
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            backgroundColor="#d7dbd8"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h7" sx={{ paddingRight: 7 }}>
                {msg}
              </Typography>
              <Button onClick={downloadAttendees}>
                Download Picnic Attendees
              </Button>
            </Container>
          </Box>
        </Box>
      ) : (
        <h1>Not Admin</h1>
      )}
      {currentUserIsAuth ? (
        <h1>Signed In</h1>
      ) : (
        <>
          {" "}
          <Grid container spacing={1} sx={{ paddingLeft: 7 }} columns={6}>
            {registeredStatus === Status.Success ||
            registeredStatus === Status.Failure ||
            registeredStatus === Status.AfterSend ||
            registeredStatus === Status.Previously ? null : (
              <>
                <Grid item xs={3} sm={3}>
                  <Typography variate="h5" align="left" fontWeight="bold">
                    Please enter your phone number then click "Send Code to
                    Phone" to sign in as an admin.
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={4}>
                  <PhoneInput
                    error={phoneError}
                    required
                    fullWidth
                    disableCountryCode={false}
                    countryCodeEditable={false}
                    onlyCountries={["us"]}
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    autoComplete="phone"
                    country={"us"}
                    value={phoneNumber}
                    onChange={(value, country, e, name) => {
                      onPhoneChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button
                    //   type="submit"
                    ref={sendCodeButtonRef}
                    id="send-code-button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={sendCodeToPhone}
                  >
                    {registeredStatus === Status.BadCodeAuth
                      ? " Re-Send Code to Phone"
                      : "Send Code to Phone"}
                  </Button>
                </Grid>
              </>
            )}

            {registeredStatus === Status.Success ||
            registeredStatus === Status.Failure ||
            registeredStatus === Status.BeforeSend ||
            registeredStatus === Status.Previously ? null : (
              <>
                <Typography
                  variant="h6"
                  style={{ color: "#FA2200" }}
                ></Typography>
                <Grid item xs={12}>
                  {registeredStatus === Status.BadCodeAuth ? (
                    <Typography variant="h7" style={{ color: "#FA2200" }}>
                      {" "}
                      The code you entered was incorrect. Please try again or
                      click 'Re-Send' above.
                    </Typography>
                  ) : (
                    <Typography variant="h7">
                      Sent! Please check your phone for a text message from us."
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variate="h5" align="left" fontWeight="bold">
                    Enter CODE from your phone here:
                  </Typography>

                  <TextField
                    //   required
                    error={verificationCodeError}
                    value={verificationCode}
                    fullWidth
                    id="otpCode"
                    label="Code"
                    name="otpCode"
                    onChange={onVerificationCodeChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    //   type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleVerifyCode}
                  >
                    Submit code to complete registration
                  </Button>{" "}
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <Typography
                      sx={{ mt: 3 }}
                      variant="h7"
                      style={{ color: "#FA2200" }}
                    >
                      {verificationCodeError
                        ? "Please enter the code that was sent to your phone.  It should be 6 digits."
                        : ""}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <List>
            {errorList.map((errorText, i) => {
              return (
                <ListItem key={i} disablePadding sx={{ ml: 3 }}>
                  <ListItemText
                    primary={errorText}
                    style={{ color: "#FA2200" }}
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </div>
  );
};

export default Admin;
